import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import config from '../config.js';
import './AdminDashboard.css';

const products = [
  "Download Portal General Access",
  "Custom X",
  "eXs",
  "G-Info",
  "Map Edit",
  "ACAD Toolbox",
  "Bim Booster",
  "Material Browser",
  "Multitool",
  "PDM Booster",
  "Praxispaket Stahlbau",
  "QTO Booster 8",
  "Steelwork",
];

const emailToProductMap = {
  "umar.aziz@mum.de": ["Download Portal General Access"],
  "thomas.seibel@customx.de": ["Custom X"],
  "edvard.lenard@mum.de": ["eXs"],
  "peter.buss@mum.de": ["G-Info"],
  "thomas.hiller@mum.de": ["Map Edit"],
  "nancy.schley@mum.de": ["ACAD Toolbox", "Bim Booster", "QTO Booster 8"],
  "roland.lackner@mum.at": ["Material Browser"],
  "tobias.wiesendanger@mum.ch": ["Multitool"],
  "marcel.decressin@mum.de": ["PDM Booster"],
  "sebastian.eiche@mum.de": ["Praxispaket Stahlbau"],
  "tom.duck@mum.de": ["Steelwork"],
};

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [userLicenses, setUserLicenses] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [userEmail, setUserEmail] = useState("");
  const [userProducts, setUserProducts] = useState([]);

  const isProductOwner = userEmail === "umar.aziz@mum.de";
  useEffect(() => {
    const fetchUserEmailAndProducts = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        const email = currentUser.attributes.email;
        setUserEmail(email);

        // Determine the products managed by this product manager
        if (emailToProductMap[email]) {
          setUserProducts(emailToProductMap[email]);
        } else {
          alert("You do not have access to any products.");
          // Redirect or restrict access here if needed
          return;
        }
      } catch (error) {
        console.error("Error fetching user data", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserEmailAndProducts();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        const response = await fetch(`${config.api.invokeUrl}/Users`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const usersData = await response.json();
        //console.log('Fetched users:', usersData);
        const filteredUsers = usersData.filter(
          (user) => user.email !== userEmail
        );
        setUsers(filteredUsers.sort((a, b) => a.email.localeCompare(b.email))); // Sort users alphabetically
      } catch (error) {
        console.error('Error fetching users', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, [userEmail]);

  useEffect(() => {
    const fetchAllUserLicenses = async () => {
      try {
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        const licensesPromises = users.map(user =>
          fetch(`${config.api.invokeUrl}/UserLicenses?email=${encodeURIComponent(user.email)}`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
        );

        const licensesResponses = await Promise.all(licensesPromises);
        const licensesData = await Promise.all(licensesResponses.map(res => res.json()));
        const licensesObject = users.reduce((acc, user, index) => {
          const licensesArray = Array.isArray(licensesData[index].licenses) ? licensesData[index].licenses : [];
          acc[user.email] = {
            licenses: licensesArray.reduce((licensesAcc, license) => {
              licensesAcc[license] = true;
              return licensesAcc;
            }, {}),
            isAdmin: user.groups.includes('Download-Portal-Admins')
          };
          return acc;
        }, {});

        setUserLicenses(licensesObject);
      } catch (error) {
        console.error('Error fetching user licenses', error);
      }
    };

    if (users.length > 0) {
      fetchAllUserLicenses();
    }
  }, [users]);

  const handleLicenseChange = (email, product) => {
    setUserLicenses(prevLicenses => ({
      ...prevLicenses,
      [email]: {
        ...prevLicenses[email],
        licenses: {
          ...prevLicenses[email].licenses,
          [product]: !prevLicenses[email].licenses[product]
        }
      }
    }));
  };

  const handleAdminChange = async (email, isChecked) => {
    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
  
      const action = isChecked ? 'add' : 'remove';
      const response = await fetch(`${config.api.invokeUrl}/UserGroup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ email, action, group: 'Download-Portal-Admins' })
      });
  
      if (!response.ok) {
        const errorMessage = await response.json();
        console.error('Error response from server:', errorMessage);
        throw new Error(`Failed to update user group: ${errorMessage.message}`);
      }
  
      setUserLicenses(prevLicenses => ({
        ...prevLicenses,
        [email]: {
          ...prevLicenses[email],
          isAdmin: isChecked
        }
      }));
    } catch (error) {
      console.error('Error updating user group membership', error);
    }
  };

  const saveLicenses = async (email) => {
  try {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    const { licenses, isAdmin } = userLicenses[email];
    const licensesArray = Object.keys(licenses).filter(key => licenses[key]);

    const response = await fetch(`${config.api.invokeUrl}/UserLicenses`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ email, licenses: licensesArray, isAdmin })
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      console.error('Error response from server:', errorResponse);
      throw new Error('Failed to save licenses');
    }

    alert('Licenses updated successfully');
  } catch (error) {
    console.error('Error updating licenses', error);
  }
};

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      window.location.reload();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleUserClick = (email) => {
    setSelectedUser(selectedUser === email ? '' : email);
  };

  const filteredUsers = users.filter(user => user.email.toLowerCase().includes(searchQuery.toLowerCase()));
  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <div className="admin-container">
      <h1 className="admin-heading">Admin Dashboard</h1>
      <p className="admin-subheading">Only accessible to Super Admins</p>
      <h5>{userEmail}</h5>
      <button className="admin-button" onClick={handleLogout}>Logout</button>
      <div>
        <input
          type="text"
          className="admin-search"
          placeholder="Search users..."
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>
      <div className="table-container">
        <div className="table-wrapper">
          <table className="admin-table">
            <thead>
              <tr>
                <th>Emails</th>
                {products
                  .filter((product) => isProductOwner || userProducts.includes(product))
                  .map((product) => (
                    <th key={product}>{product}</th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user) => (
                <tr key={user.email} onClick={() => handleUserClick(user.email)}>
                  <td className="email-cell">{user.email}</td>
                  {isProductOwner
                    ? products.map((product) => (
                        <td key={`${product}-${user.email}`}>
                          {product === "Download Portal General Access" ? (
                            <>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={
                                    userLicenses[user.email]?.licenses[product] ||
                                    false
                                  }
                                  onChange={() =>
                                    handleLicenseChange(user.email, product)
                                  }
                                  disabled
                                />
                                <span className="slider"></span>
                              </label>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <label>
                                Admin&nbsp;
                                <input
                                  type="checkbox"
                                  checked={
                                    userLicenses[user.email]?.isAdmin || false
                                  }
                                  onChange={(e) =>
                                    handleAdminChange(
                                      user.email,
                                      e.target.checked
                                    )
                                  }
                                  disabled
                                />
                              </label>
                            </>
                          ) : (
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  userLicenses[user.email]?.licenses[product] ||
                                  false
                                }
                                onChange={() =>
                                  handleLicenseChange(user.email, product)
                                }
                                disabled
                              />
                              <span className="slider"></span>
                            </label>
                          )}
                        </td>
                      ))
                    : products
                        .filter((product) => userProducts.includes(product))
                        .map((product) => (
                          <td key={`${product}-${user.email}`}>
                            {product === "Download Portal General Access" ? (
                              <>
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={
                                      userLicenses[user.email]?.licenses[
                                        product
                                      ] || false
                                    }
                                    onChange={() =>
                                      handleLicenseChange(user.email, product)
                                    }
                                    disabled
                                  />
                                  <span className="slider"></span>
                                </label>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label>
                                  Admin&nbsp;
                                  <input
                                    type="checkbox"
                                    checked={
                                      userLicenses[user.email]?.isAdmin || false
                                    }
                                    onChange={(e) =>
                                      handleAdminChange(
                                        user.email,
                                        e.target.checked
                                      )
                                    }
                                    disabled
                                  />
                                </label>
                              </>
                            ) : (
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={
                                    userLicenses[user.email]?.licenses[
                                      product
                                    ] || false
                                  }
                                  onChange={() =>
                                    handleLicenseChange(user.email, product)
                                  }
                                  disabled
                                />
                                <span className="slider"></span>
                              </label>
                            )}
                          </td>
                        ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div
          className={`main-dashboard-overlay ${selectedUser ? "active" : ""}`}
          onClick={() => setSelectedUser("")}
        ></div>
        {selectedUser && (
          <div className={`flyout-panel ${selectedUser ? "open" : ""}`}>
            <h3>{selectedUser}</h3>
            {isProductOwner
              ? products.map((product) => (
                  <div key={product} className="switch-container">
                    <label>{product}</label>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={
                          userLicenses[selectedUser]?.licenses[product] || false
                        }
                        onChange={() =>
                          handleLicenseChange(selectedUser, product)
                        }
                      />
                      <span className="slider"></span>
                    </label>
                  </div>
                ))
              : products
                  .filter((product) => userProducts.includes(product))
                  .map((product) => (
                    <div key={product} className="switch-container">
                      <label>{product}</label>
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={
                            userLicenses[selectedUser]?.licenses[product] ||
                            false
                          }
                          onChange={() =>
                            handleLicenseChange(selectedUser, product)
                          }
                        />
                        <span className="slider"></span>
                      </label>
                    </div>
                  ))}
            {(isProductOwner ||
              (userProducts === "Download Portal General Access" &&
                userLicenses[selectedUser]?.licenses[
                  "Download Portal General Access"
                ])) && (
              <div className="switch-container">
                <label>Admin General Access</label>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={userLicenses[selectedUser]?.isAdmin || false}
                    onChange={(e) =>
                      handleAdminChange(selectedUser, e.target.checked)
                    }
                  />
                  <span className="slider"></span>
                </label>
              </div>
            )}
            <button
              className="admin-button"
              onClick={() => saveLicenses(selectedUser)}
            >
              Save Licenses
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
